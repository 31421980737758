













































export type PaddingOptions = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    additionalInfoStyle(this: any): {
      color?: string;
    } {
      return {
        color: this.blok.additionalInfoColor
          ? `var(--${this.blok.additionalInfoColor})`
          : undefined,
      };
    },
    paddingStyle(this: any) {
      const paddingTopDesktop: PaddingOptions =
        this.blok.paddingTopDesktop || 'xxl';
      const paddingBottomDesktop: PaddingOptions =
        this.blok.paddingBottomDesktop || 'xxl';
      const paddingTopMobile: PaddingOptions =
        this.blok.paddingTopMobile || 'sm';
      const paddingBottomMobile: PaddingOptions =
        this.blok.paddingBottomMobile || 'sm';
      const buttonSpacingTopDesktop: PaddingOptions =
        this.blok.buttonSpacingTopDesktop || 'sm';
      const buttonSpacingTopMobile: PaddingOptions =
        this.blok.buttonSpacingTopMobile || 'sm';
      const logoBarSpacingTopDesktop: PaddingOptions =
        this.blok.logoBarSpacingTopDesktop || 'xxl';
      const logoBarSpacingTopMobile: PaddingOptions =
        this.blok.logoBarSpacingTopMobile || 'sm';

      const paddingOptions = {
        none: '0',
        xs: 'var(--spacing-xs)',
        sm: 'var(--spacing-sm)',
        md: 'var(--spacing-md)',
        lg: 'var(--spacing-lg)',
        xl: 'var(--spacing-xl)',
        xxl: 'var(--spacing-xxl)',
      };

      return {
        '--padding-top-desktop': paddingOptions[paddingTopDesktop],
        '--padding-bottom-desktop': paddingOptions[paddingBottomDesktop],
        '--padding-top-mobile': paddingOptions[paddingTopMobile],
        '--padding-bottom-mobile': paddingOptions[paddingBottomMobile],
        '--button-spacing-top-desktop': paddingOptions[buttonSpacingTopDesktop],
        '--button-spacing-top-mobile': paddingOptions[buttonSpacingTopMobile],
        '--logo-bar-spacing-top-desktop':
          paddingOptions[logoBarSpacingTopDesktop],
        '--logo-bar-spacing-top-mobile':
          paddingOptions[logoBarSpacingTopMobile],
      };
    },
  },
};
